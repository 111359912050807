import Rails from "@rails/ujs"
import "@rails/actioncable"
Rails.start()

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'mark.js/dist/jquery.mark.es6';
import * as bootstrap from 'bootstrap';
import "../components/polyfill";

import {btnColorBootstrapOverride} from "../components/btn_color_bootstrap_override"

import {execJSextra, execJSall, execJSnav} from "../packs/index"

document.addEventListener('turbo:render', function() {
	execJSall();
	// execJSnav();
})
document.addEventListener('turbo:load', function() {
	execJSall();
	execJSnav();
	execJSextra();
})

// pour résoudre le problème de bouttons blancs quand actif:
btnColorBootstrapOverride();

	// enleve turbo avec la classe '.no-turbo'sortingActiveInactiveMealItems
	document.addEventListener('turbo:click', function (event) {
		const link = event.target.closest('.no-turbo');
		if (link) {
			event.preventDefault();
			Turbo.visit(link.href);
		}
	});

document.addEventListener("turbo:before-cache", function() {
	const loadingDotsContainer = document.querySelector(".dots-container")

	if (loadingDotsContainer && !loadingDotsContainer.classList.contains("hidden")) {
		loadingDotsContainer.classList.add("hidden")
		document.querySelector("body").classList.remove("background-gray");
	}
})

// Fonction pour ajouter l'attribut data-turbo-prefetch="false" à tous les liens
function addTurboPrefetchAttribute() {
  const links = document.querySelectorAll('a');
  links.forEach(function(link) {
    link.setAttribute('data-turbo-prefetch', 'false');
  });
}

// Créer une instance de MutationObserver
const observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    // Si des nœuds enfants ont été ajoutés ou supprimés, ajouter l'attribut aux nouveaux liens
    if (mutation.type === 'childList') {
      addTurboPrefetchAttribute();
    }
  });
});

// Configurer l'observateur pour surveiller les modifications du DOM
const config = {
  childList: true,       // Surveiller les ajouts ou suppressions de nœuds enfants
  subtree: true          // Surveiller les modifications dans les sous-arbres du nœud cible
};

// Démarrer l'observation du document entier
observer.observe(document, config);

// Ajouter l'attribut aux liens existants lors du chargement initial du DOM
document.addEventListener('DOMContentLoaded', function() {
  addTurboPrefetchAttribute();
});
