import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

export default class extends Controller {
  static values = { downloadId: Number }

  connect() {
    this.setupActionCable();
    this.addFlatpickrToDateInputs();
    this.setupDownloadButtons();
    // Ajouter un listener pour surveiller les changements d'URL
    window.addEventListener('popstate', this.handleUrlChange.bind(this));
  }

  setupActionCable() {
    const subscriptionKey = `download-${this.downloadIdValue}`;

    // verifier si il n y a pas de souscription déjà en cours pour créer la variable qui check les souscriptions
    if (!window.downloadSubscriptions) {
      window.downloadSubscriptions = {};
    }

    // verfier s'il n y a pas de souscription déjà en cours
    if (!window.downloadSubscriptions[subscriptionKey]) {
      // si non, créer un nouveau cable (souscription)
      window.downloadSubscriptions[subscriptionKey] = createConsumer().subscriptions.create(
        { channel: "DownloadChannel", id: this.downloadIdValue },
        { received: data => this.handleReceivedData(data) }
      );
    }

    this.channel = window.downloadSubscriptions[subscriptionKey];

  }

   // Surveiller les changements d'URL
   handleUrlChange() {
    console.log("coucou")
    const subscriptionKey = `download-${this.downloadIdValue}`;
    if (!window.downloadSubscriptions[subscriptionKey]) {
      this.setupActionCable();  // Réinitialiser le câble si nécessaire
    }
  }

  handleReceivedData(data) {
    const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
    const downloadButton = document.getElementById(data.response.id);
    if (downloadButton) {
      downloadButton.innerHTML = `${mainDiv.dataset.download}`;
      downloadButton.disabled = false;
    }
    let receivedFileName = data.response.file
    const url = `get_file/${receivedFileName}/${data.response.user}`;
    if(receivedFileName.endsWith(".pdf")) {
      window.open(url, '_blank');
    }else {
      window.location.href = url;
    }
  }

  setupDownloadButtons() {
    const buttonIds = [
      "btn-download-report",
      "btn-download-group-meal",
      "base-client-btn",
      "rgpd-btn",
      "btn-download-group-meal-resto-price",
      "download-question-enquetes-btn",
      "download-group-meal-resto-admin-btn",
      "download-enquetes-multisites-btn",
      "download-e-reputation-btn",
      "download-enquetes-return-rate-btn",
      "download-synthese-pdf"
    ];

    buttonIds.forEach(btnId => {
      this.getDownloadBtnById(btnId).then(downloadBtn => {
        this.setupDownloadButton(downloadBtn);
      });
    });
  }

  getDownloadBtnById(btnId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const downloadBtn = document.getElementById(btnId);
        resolve(downloadBtn);
      }, 50);
    });
  }

  setupDownloadButton(btn) {
    if (btn) {
      this.addDownloadingIcon(btn);
      this.showToast(btn);
    }
  }

  addDownloadingIcon(btn) {
    btn.addEventListener("click", () => {
      const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
      btn.innerHTML = `<i class="fa-solid fa-download fa-beat-fade" style="color: #63E6BE;"></i>&nbsp <i class="ms-1" style="color: #63E6BE;">${mainDiv.dataset.downloading}</i>`;
      setTimeout(() => {
        btn.disabled = true;
      }, 50);
    });
  }

  showToast(btn) {
    btn.addEventListener("click", () => {
      const toastDiv = document.getElementById("toast-box");
      const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
      if (toastDiv.innerHTML.trim() === "") {
        let toast = document.createElement('div');
        toast.classList.add('toast_custom');
        toast.innerHTML = `<i class="fa-solid fa-download"></i><h5>${mainDiv.dataset.toastWait}</h5>`;
        toastDiv.appendChild(toast);
        setTimeout(() => {
          toast.remove();
        }, 3000);
      }
    });
  }

  addFlatpickrToDateInputs() {
    const startDateFlatpickr = flatpickr(".start-date", {
      dateFormat: "Y-m-d",
      onChange: (selectedDates, dateStr, instance) => {
        this.endDateFlatpickr.set("minDate", dateStr);
      }
    });

    this.endDateFlatpickr = flatpickr(".end-date", {
      dateFormat: "Y-m-d",
    });

    if(startDateFlatpickr.calendarContainer && this.endDateFlatpickr.calendarContainer) {
      startDateFlatpickr.calendarContainer.classList.add("custom-flatpickr-calendar");
      this.endDateFlatpickr.calendarContainer.classList.add("custom-flatpickr-calendar");
    }

  }
}
