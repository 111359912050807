import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["column", "cell", "toggleButton", "toggleContainer"]

  connect() {
    this.restoreColumnState()
    this.updateColumnWidths()
    this.checkToggleButtonVisibility()
  }

  hideColumn(event) {
    const index = event.currentTarget.dataset.index

    this.columnTargets.forEach(column => {
      if (column.dataset.index === index) {
        column.classList.add("hidden-column")
        column.dataset.hidden = "true"
      }
    })

    this.cellTargets.forEach(cell => {
      if (cell.dataset.index === index) {
        cell.classList.add("hidden-column")
        cell.dataset.hidden = "true"
      }
    })

    this.saveColumnState()
    this.updateColumnWidths()
    this.checkToggleButtonVisibility()
  }

  showNextColumn(event) {
    let lastHiddenIndex = null
    for (let i = this.columnTargets.length - 1; i >= 0; i--) {
      if (this.columnTargets[i].dataset.hidden === "true") {
        lastHiddenIndex = this.columnTargets[i].dataset.index
        break
      }
    }

    if (lastHiddenIndex !== null) {
      this.columnTargets.forEach(column => {
        if (column.dataset.index === lastHiddenIndex) {
          column.classList.remove("hidden-column")
          column.dataset.hidden = "false"
        }
      })

      this.cellTargets.forEach(cell => {
        if (cell.dataset.index === lastHiddenIndex) {
          cell.classList.remove("hidden-column")
          cell.dataset.hidden = "false"
        }
      })

      this.saveColumnState()
      this.updateColumnWidths()
      this.checkToggleButtonVisibility()
    }
  }

  restoreColumnState() {
    const columnStates = JSON.parse(localStorage.getItem("columnStates")) || {}
    this.columnTargets.forEach((column, index) => {
      if (columnStates[index] === "true") {
        column.classList.add("hidden-column")
        column.dataset.hidden = "true"
      } else {
        column.classList.remove("hidden-column")
        column.dataset.hidden = "false"
      }
    })

    this.cellTargets.forEach((cell, index) => {
      if (columnStates[cell.dataset.index] === "true") {
        cell.classList.add("hidden-column")
        cell.dataset.hidden = "true"
      } else {
        cell.classList.remove("hidden-column")
        cell.dataset.hidden = "false"
      }
    })
  }

  saveColumnState() {
    const columnStates = {}
    this.columnTargets.forEach((column, index) => {
      columnStates[index] = column.dataset.hidden
    })
    localStorage.setItem("columnStates", JSON.stringify(columnStates))
  }

  checkToggleButtonVisibility() {
    const hasHiddenColumns = this.columnTargets.some(column => column.dataset.hidden === "true")
    const toggleButton = this.toggleButtonTargets[0]

    if (hasHiddenColumns) {
      toggleButton.style.display = 'inline-flex !important'
      this.toggleContainerTarget.classList.remove("hidden")
    } else {
      toggleButton.style.display = 'none !important'
      this.toggleContainerTarget.classList.add("hidden")

    }
  }

  updateColumnWidths() {
    const visibleColumns = this.columnTargets.filter(column => 
      column.dataset.hidden !== "true" && 
      !column.classList.contains("fixed-column") && 
      !column.classList.contains("fixed-column-small")
    );
    
    const columnCount = visibleColumns.length;
  
    if (columnCount === 0) return;
  
    const widthPercentage = 100 / columnCount;
  
    this.columnTargets.forEach(column => {
      if (column.dataset.hidden !== "true" && 
          !column.classList.contains("fixed-column") && 
          !column.classList.contains("fixed-column-small")) {
        column.style.width = `${widthPercentage}%`;
      }
    });
  
    this.cellTargets.forEach(cell => {
      if (cell.dataset.hidden !== "true" && 
          !cell.classList.contains("fixed-column") && 
          !cell.classList.contains("fixed-column-small")) {
        cell.style.width = `${widthPercentage}%`;
      }
    });
}

}
